import React, { useEffect } from "react";
import AlertPopup from "../../../molecules/AlertPopup";
import dataLayer from "../../../utils/dataLayer";

const CLOSE_DATALAYER_KEY = "closePopupPassportOver2";
const REDIRECT_DATALAYER_KEY = "redirectToD11FromPopupPassportOver2";
const SHOWUP_DATALAYER_KEY = "showUpPopupPassportOver2";

const PassportOver2Alert = ({ onClose }) => {
  const handleOnClose = () => {
    dataLayer.push(CLOSE_DATALAYER_KEY);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnRedirect = () => {
    dataLayer.push(REDIRECT_DATALAYER_KEY);
  };

  useEffect(() => {
    dataLayer.push(SHOWUP_DATALAYER_KEY);
  }, []);

  return (
    <AlertPopup
      title="We noticed your passport is older than two years. You cannot replace your passport using this form."
      alertMessage="If you have entered the wrong information, correct your mistake, and continue with your application."
      onClose={handleOnClose}
      content={
        <>
          <b>Remember:</b> If your passport is older than two years, it does not meet the
          requirements for passport replacement. You need to apply for a new passport.
        </>
      }
      redirect={{
        to: "/new-passport",
        label: "Get a new passport with the DS-11 form",
        onClick: handleOnRedirect,
      }}
    />
  );
};

export default PassportOver2Alert;
