import React, { useEffect } from "react";
import AlertPopup from "../../../molecules/AlertPopup";
import dataLayer from "../../../utils/dataLayer";

const CLOSE_DATALAYER_KEY = "closePopupNotYourFormOver15";
const REDIRECT_DATALAYER_KEY = "redirectToD11FromPopupNotYourFormOver15";
const SHOWUP_DATALAYER_KEY = "showUpPopupNotYourFormOver15";

const Over15Alert = ({ onClose }) => {
  const handleOnClose = () => {
    dataLayer.push(CLOSE_DATALAYER_KEY);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnRedirect = () => {
    dataLayer.push(REDIRECT_DATALAYER_KEY);
  };

  useEffect(() => {
    dataLayer.push(SHOWUP_DATALAYER_KEY);
  }, []);

  return (
    <AlertPopup
      title="You applied for your last passport over 15 years ago."
      alertMessage="If you entered the wrong issuing date simply correct the error and continue filling in the current form as normal."
      onClose={handleOnClose}
      content={
        <>
          <b>Remember:</b> You should use a DS-11 to complete your application if your last document
          is over 15 years old even if it isn’t your first passport.
        </>
      }
      redirect={{
        to: "/new-passport",
        label: "Go to DS-11",
        onClick: handleOnRedirect,
      }}
    />
  );
};

export default Over15Alert;
