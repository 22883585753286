import React, { useEffect } from "react";
import AlertPopup from "../../../molecules/AlertPopup";
import dataLayer from "../../../utils/dataLayer";

const CLOSE_DATALAYER_KEY = "closePopupPassportNot5504";
const REDIRECT_DATALAYER_KEY = "redirectToD11FromPopupPassportNot5504";
const SHOWUP_DATALAYER_KEY = "showUpPopupPassportNot5504";

const PassportNot5504Alert = ({ onClose }) => {
  const handleOnClose = () => {
    dataLayer.push(CLOSE_DATALAYER_KEY);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnRedirect = () => {
    dataLayer.push(REDIRECT_DATALAYER_KEY);
  };

  useEffect(() => {
    dataLayer.push(SHOWUP_DATALAYER_KEY);
  }, []);

  return (
    <AlertPopup
      title="To renew or get a new passport you must select the correct form."
      alertMessage="However, if you have entered the wrong information, correct your mistake and continue with your application."
      onClose={handleOnClose}
      content={
        <>
          <b>Remember:</b> You must answer Yes, to at least one of these questions:
          <ul>
            <li className="u-list-bullet">
              Have you changed your name since your most recent passport was issued?
            </li>
            <li className="u-list-bullet">
              Were your personal details printed incorrectly in your U.S. book or card?
            </li>
            <li className="u-list-bullet">
              Was your most recent passport book limited to two years?
            </li>
          </ul>
        </>
      }
      redirect={{
        to: "/home",
        label: "Select the correct form",
        onClick: handleOnRedirect,
      }}
    />
  );
};

export default PassportNot5504Alert;
