import React from "react";
import PropTypes from "prop-types";
import Popup from "../Popup";
import Button from "../../atoms/Button";
import { Title } from "../../atoms/Title/title";
import { ReactSVG } from "react-svg";
import Alert from "../../atoms/Alert";
import * as styles from "./alertPopup.module.scss";

const AlertPopup = ({ onClose, onLoad, title, content, alertMessage, redirect = {} }) => {
  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      handleOnClose(ev);
    }
  };

  const handleOnClose = (ev) => {
    if (typeof onClose === "function") {
      onClose(ev);
    }
  };

  const handleRedirectOnClick = (ev) => {
    if (typeof redirect === "object" && typeof redirect.onClick === "function") {
      redirect.onClick(ev);
    }
  };

  return (
    <Popup
      data-testid="alert-popup"
      onLoad={onLoad}
      className={styles.popup}
      onClickOutside={handleOnClose}
      onKeypressEsc={handleOnClose}>
      <div className={styles.header}>
        <div
          className={styles.close}
          role="button"
          tabIndex={0}
          onClick={handleOnClose}
          onKeyDown={handleKeyDown}>
          <ReactSVG src={"/svg/cancel.svg"} />
        </div>
      </div>

      <div className={styles.body}>
        {title && (
          <Title
            regular
            content={title}
          />
        )}
        {content}
      </div>
      <div className={styles.action}>
        <Button
          full
          primary
          label={redirect.label}
          link={redirect.to}
          svgSrc="/svg/arrow_right_24px.svg"
          onClick={handleRedirectOnClick}
        />
      </div>
      <div className={styles.alert}>
        <Alert
          type="warning"
          content={alertMessage}
        />
      </div>
    </Popup>
  );
};

AlertPopup.propTypes = {
  onClose: PropTypes.func,
  onLoad: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.element.isRequired,
  alertMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  redirect: PropTypes.exact({
    to: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default AlertPopup;
