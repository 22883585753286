import React, { useState, useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import FormComponents from "../../Form/FormComponents";
import Under16Alert from "./Alerts/Under16Alert";
import Over15Alert from "./Alerts/Over15Alert";
import PassportOver1Alert from "./Alerts/PassportOver1Alert";
import PassportOver2Alert from "./Alerts/PassportOver2Alert";
import PassportNot5504Alert from "./Alerts/PassportNot5504Alert";
import ActionButtons from "./ActionButtons";
import Loading from "../../atoms/Loading";
import FormContext from "../../../context/FormContext";
import AuthForm from "./AuthForm";
import StepWizardContext from "../../../context/StepWizardContext";
import Alert from "../../atoms/Alert";
import useHandleralertPopup, {
  SHOW_OVER_15_KEY_ACTION,
  SHOW_PASSPORT_BOOK_OVER_1_KEY_ACTION,
  SHOW_PASSPORT_CARD_OVER_1_KEY_ACTION,
  SHOW_PASSPORT_BOOK_OVER_2_KEY_ACTION,
  SHOW_PASSPORT_CARD_OVER_2_KEY_ACTION,
  SHOW_PASSPORT_NOT_5504_KEY_ACTION,
  SHOW_UNDER_16_KEY_ACTION,
} from "./hooks/useHandleralertPopup";
import { createApplication, editApplication } from "./services/order";
import { refHandler } from "../../../services/componentSwitcher/ComponentSwitcher";
import { stepValidator } from "../../utils/formValidation";
import { scrollBehaviorTo } from "../../utils";
import { buildFormToSubmit } from "../../utils/form";
import usePrepareAmplitudeData from "../../../hooks/usePrepareAmplitudeData";
import { amplitudeTrackEvent } from "../../utils/Amplitude";
import "./styles.scss";

const Form = ({
  addons,
  formName,
  formType,
  formDescription,
  formPrice,
  structure,
  orderCookie,
  isFormToEdit,
  updateAddons,
  source,
}) => {
  const { step, nextStep, previousStep, currentStep, totalSteps } = useContext(StepWizardContext);
  const {
    form,
    onChangeField,
    fieldValidator,
    formValidator,
    addItemForm,
    formHasValueDependences,
    setValuesDependences,
  } = useContext(FormContext);

  const { alertsPopup, verifyAlertsPopup, closeAlertPopup } = useHandleralertPopup();
  const [loading, setLoading] = useState(false);

  const { eventName, eventOptions } = usePrepareAmplitudeData(
    currentStep,
    step.name,
    formType,
    source
  );

  useEffect(() => {
    scrollBehaviorTo(0);
    amplitudeTrackEvent(eventName, eventOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (addons) {
      if (addons.filter((item) => item.identifier === "card").length) {
        addItemForm("addonsCard", { value: "hide" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formHasValueDependences) {
      setValuesDependences(step.allFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasValueDependences && currentStep]);

  const submitApplication = useCallback(
    async (stepIndex, isEditingSection = false) => {
      try {
        if (!stepValidator(stepIndex, formValidator)) {
          setLoading(false);
          return false;
        }

        setLoading(true);
        if (
          typeof orderCookie.applicationId === "string" &&
          orderCookie.pdfId === form.pdfId.value
        ) {
          await editApplication(form, orderCookie, structure, stepIndex, isEditingSection);
        } else {
          await createApplication(form, formType, formDescription, formPrice, structure);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, formValidator, orderCookie, structure]
  );

  const handleNextStep = useCallback(() => {
    if (stepValidator(currentStep, formValidator)) {
      nextStep(currentStep);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, formValidator]);

  const handleOnChange = (event) => {
    const newForm = onChangeField(event);
    const formUsedFields = buildFormToSubmit(structure.steps, newForm);
    verifyAlertsPopup(event, formName, formUsedFields);

    if (event.target.name === "addons" && typeof updateAddons === "function") {
      updateAddons(newForm.addons.value);
    }
  };

  const handleOnBlur = (event) => {
    let form;

    if (typeof event.target.value === "string") {
      event.target.value = event.target.value.trim();
      form = onChangeField(event);
    }

    fieldValidator(event.target.name, form);
  };

  return (
    <div className="form u-box">
      <AuthForm formValidator={formValidator}>
        {loading && <Loading full />}

        {alertsPopup.showUnder16Alert && (
          <Under16Alert onClose={closeAlertPopup.bind(this, SHOW_UNDER_16_KEY_ACTION)} />
        )}
        {alertsPopup.showOver15Alert && (
          <Over15Alert onClose={closeAlertPopup.bind(this, SHOW_OVER_15_KEY_ACTION)} />
        )}
        {alertsPopup.showPassportBookOver1Alert && (
          <PassportOver1Alert
            onClose={closeAlertPopup.bind(this, SHOW_PASSPORT_BOOK_OVER_1_KEY_ACTION)}
          />
        )}
        {alertsPopup.showPassportCardOver1Alert && (
          <PassportOver1Alert
            onClose={closeAlertPopup.bind(this, SHOW_PASSPORT_CARD_OVER_1_KEY_ACTION)}
          />
        )}
        {alertsPopup.showPassportBookOver2Alert && (
          <PassportOver2Alert
            onClose={closeAlertPopup.bind(this, SHOW_PASSPORT_BOOK_OVER_2_KEY_ACTION)}
          />
        )}
        {alertsPopup.showPassportCardOver2Alert && (
          <PassportOver2Alert
            onClose={closeAlertPopup.bind(this, SHOW_PASSPORT_CARD_OVER_2_KEY_ACTION)}
          />
        )}
        {alertsPopup.showPassportNot5504Alert && (
          <PassportNot5504Alert
            onClose={closeAlertPopup.bind(this, SHOW_PASSPORT_NOT_5504_KEY_ACTION)}
          />
        )}

        <form
          className="doc"
          onSubmit={undefined}>
          <div className="doc-container">
            <div className={`doc-step ${step.name}`}>
              {step.label !== "" && (
                <h1 className="extra-large">
                  {step.label.split("<div>")[0]}
                  {typeof step.subLabel !== "undefined" && step.subLabel !== "" && (
                    <p className="form-subTitle small">{step.subLabel}</p>
                  )}
                </h1>
              )}

              {step.groups.map((group, index) => {
                return (
                  <div
                    className={`group-${index} ${group.name}-wrapper ${
                      group.gridClass ? group.gridClass : ""
                    }`}
                    key={`Group-${index}`}>
                    {group.disclaimer && (
                      <Alert
                        type="warning"
                        content={group.disclaimer}
                        className="form__disclaimer"
                        showLeftIcon
                      />
                    )}
                    {refHandler(group, form) && (
                      <div
                        className={`doc-group ${group.class} u-flex u-flex--wrap u-flex--justify-between`}>
                        {group.label && <p className="group-title big">{group.label}</p>}
                        {group.subLabel && (
                          <p className="group-subtitle medium">{group.subLabel}</p>
                        )}

                        {group.fields.map((item) => {
                          const field = form[item];
                          const FieldComponent = FormComponents[field.component];

                          if (!group.hide) {
                            return (
                              <React.Fragment key={item}>
                                {refHandler(field, form) && (
                                  <FieldComponent
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                    addons={addons}
                                    {...field}
                                  />
                                )}
                              </React.Fragment>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </form>

        <ActionButtons
          loading={loading}
          currentStep={currentStep}
          totalSteps={totalSteps}
          submitApplication={submitApplication}
          nextStep={handleNextStep}
          previousStep={previousStep}
          isFormToEdit={isFormToEdit}
          editApplication={editApplication}
        />
      </AuthForm>
    </div>
  );
};

Form.propTypes = {
  formName: PropTypes.string.isRequired,
  structure: PropTypes.object.isRequired,
  orderCookie: PropTypes.object.isRequired,
  addons: PropTypes.array,
  isFormToEdit: PropTypes.bool,
  updateAddons: PropTypes.func,
};

export default Form;
