import { EditApplicationHandler } from "../../../../services/api/EditApplication/EditOrderHandler";
import { CreateOrderHandler } from "../../../../services/api/CreateOrder/CreateOrderHandler";
import { clearFormDataInStorage } from "../../../utils/formDataInStorage";
import { getUtmsCookies } from "../../../utils/SEM/UTM/checkUtmParams";
import { buildFormToSubmit } from "../../../utils/form";
import { setCookies } from "../../../utils/cookies";
import { getOriginUrl } from "../../../utils";
import { navigate } from "gatsby";

export const editApplication = async (
  form,
  orderCookie,
  structure,
  stepIndex,
  isEditingSection
) => {
  const editApplication = new EditApplicationHandler({});
  try {
    await editApplication.customAction({
      applicationId: orderCookie.applicationId,
      ...buildFormToSubmit(structure.steps, form),
    });
    const editedStepName = structure.steps[stepIndex].name;
    const pdfName = form.pdfId.name;

    isEditingSection
      ? (window.location = `/review-${pdfName}#${editedStepName}`)
      : (window.location = `/review-${pdfName}`);
  } catch (error) {
    throw error;
  }
};

export const createApplication = async (form, formType, formDescription, formPrice, structure) => {
  try {
    const orderHandler = new CreateOrderHandler({});

    const response = await orderHandler.customAction({
      ...getUtmsCookies(),
      ...buildFormToSubmit(structure.steps, form),
    });

    clearFormDataInStorage();

    const urlOrigin = getOriginUrl();

    setCookies("address_token", {
      application: {
        formName: formType.toUpperCase(),
        formDescription: formDescription,
      },
      regularPrice: {
        name: form.pdfId.name,
        amount: formPrice,
      },
      pdfId: form.pdfId.value,
      order: response.applicationId,
      applicationId: response.applicationId,
      reference: response.reference,
      urlRedirect: response.urlRedirect,
      urlOrigin: urlOrigin,
    });

    const pagePath = `review-${form.pdfId.name}`;

    navigate(`/${pagePath}`);
  } catch (error) {
    throw error;
  }
};
